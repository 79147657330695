.header-area {
  position: relative;
  z-index: 1030;
  &.fixed-top {
    position: fixed;
  }
}
.fixed-top {
  @include box-shadow(0 1px 10px rgba(82, 85, 90, 0.08));
  @include transition(0.4s);
}
/*===== header-top-item =====*/
.header-top-item {
  @media #{$tab_device} {
    text-align: center!important;
  }
   @media #{$large_mobile} {
    text-align: center!important;
  }
   @media #{$small_mobile} {
    text-align: center!important;
  }
  .list-item {
    li {
      margin-bottom: 0;
    }
  }
}
/*===== main-menu =====*/
.main-menu {
  @media #{$tab_device} {
    display: none;
  }
  @media #{$large_mobile} {
    display: none;
  }
  @media #{$small_mobile} {
    display: none;
  }
  >ul {
    >li {
      display: inline-block;
      margin-right: 25px;
      text-transform: capitalize;
      position: relative;
      font-weight: $font-weight-medium;
      &.has-mega-menu {
        position: static;
      }
      >a {
        color: $theme-color;
        @include transition(0.2s);
        display: block;
        padding-bottom: 27px;
        margin-bottom: -27px;
        &:hover {
          color: $theme-color-2;
        }
      }
      .dropdown-menu-item {
        position: absolute;
        top: 60px;
        left: 0;
        width: 200px;
        background-color: $white;
        @include border-radius(4px);
        padding: 10px 20px;
        @include box-shadow(0 1px 10px rgba(0,0,0, 0.1));
        @include transition(0.3s);
        opacity: 0;
        visibility: hidden;
        li {
          a {
            color: $theme-color-4;
            font-size: $font-15;
            font-weight: $font-weight-medium;
            @include transition(0.2s);
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-top: 5px;
            padding-bottom: 5px;
            &:hover {
              color: $theme-color-2;
            }
          }
        }
      }
      .mega-menu {
        width: 100%;
        >li {
          &:not(:last-child) {
            border-right: 1px solid $gray-2;
          }
        }
      }
      &:hover {
        .dropdown-menu-item {
          top: 50px;
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
}
/*===== user-action-list =====*/
.user-action-list {
  li {
    display: inline-block;
    position: relative;
    &:not(:last-child) {
      margin-right: 25px;
    }
    a {
      color: $theme-color;
      @include transition(0.2s);
      &:hover {
        color: $theme-color-2;
      }
    }
    .item-count {
      position: absolute;
      top: -7px;
      right: -15px;
      color: $white;
      text-align: center;
      font-size: $font-14;
      font-weight: $font-weight-semi-bold;
      background-color: $theme-color-2;
      @include border-radius(100%);
      width: 20px;
      height: 20px;
      line-height: 23px;
    }
    &.search-dropdown {
      position: static;
    }
  }
}
/*===== search-dropdown ======*/
.search-dropdown {
  .dropdown-toggle {
    background-color: transparent;
    border: 0;
    @include transition(0.2s);
    outline: none;
    width: 20px;
    &:after {
      display: none;
    }
    &:hover {
      color: $theme-color-2;
    }
    &[aria-expanded=true] {
      i {
        &:before {
          content: "\f00d";
        }
      }
    }
  }
  .dropdown-menu {
    width: 100%;
    margin-top: 20px;
  }
}
/*===== dropdown--menu ======*/
.dropdown--menu {
  padding: 20px;
  border: 0;
  @include box-shadow(0 1px 10px rgba(0,0,0, 0.1));
}
/*===== main-header-action ======*/
.main-header-action {
  @media #{$tab_device} {
    display: flex;
    align-items: center;
    padding-top: 20px;
  }
  @media #{$large_mobile} {
    display: flex;
    align-items: center;
    padding-top: 20px;
  }
  @media #{$small_mobile} {
    display: flex;
    align-items: center;
    padding-top: 20px;
  }
}
/*===== main-header-action-2 ======*/
.main-header-action-2 {
  @media #{$tab_device} {
    justify-content: space-between!important;
    padding-top: 0;
  }
  @media #{$large_mobile} {
    justify-content: space-between!important;
    padding-top: 0;
  }
  @media #{$small_mobile} {
    justify-content: space-between!important;
    padding-top: 0;
  }
}

/*====== off-canvas =======*/
.off-canvas,
.sidebar-filter {
  position: fixed;
  top: 0;
  left: 0;
  width: 320px;
  height: 100vh;
  background-color: $white;
  overflow-x: hidden;
  z-index: 1035;
  @include transition(0.4s cubic-bezier(0.7, 0, 0.2, 1));
  @include box-shadow(0 1px 8px rgba(0, 0, 0, 0.1));
  padding: 20px;
  @include transform(translateX(-100%));
  &.active {
    @include transform(translateX(0));
  }
}
.off-canvas-dropdown-toggle {
  &.icon-element-xs {
    border: 0;
    width: 30px;
    height: 30px;
    line-height: 35px;
  }
  &.active,
  &:hover {
    color: $theme-color-2;
  }
  &.active {
    i {
      &:before {
        content: "\f106";
      }
    }
  }
}
.off-canvas-menu {
  text-transform: capitalize;
  padding-top: 60px;
  font-weight: $font-weight-medium;
  >li {
    >a {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: $theme-color;
    }
    .off-canvas-dropdown {
      display: none;
      padding-left: 20px;
      font-size: $font-15;
    }
  }
}
.sidebar-filter-wrap {
  padding-top: 60px;
}