/*
	Template Name: Minzel
	Author: techydevs
	Description: Minzel - Business HTML Template
	Tags: one-column, two-columns, three-columns, four-columns, custom-menu, featured-images, translation-ready
	Version: 1.0
*/
/*------------------------------------------------------------------
[Table of contents]
	+ General
		- general Styles
		- common-style
		- Preloader
		- divider
		- Back To Top
	+ Header
		- Logo
		- login
		- sign up
		- language
		- Main Navigation
		- Dropdown
		- Offcanvas menu
		- Search
	+ Content
		+ Main Files
			- index.html
			- index-2.html
	+ Banner Area
			- Breadcrumb
	+ Pages
			- About
			- Case Study
			- Single Case Study
			- Team Grid
			- Team Details
			- Testimonials
			- FAQs
			- Pricing
			- 404 Error Pages
			- Sign Up
			- Login
			- Recover
	+ blog
			- Blog Full Width
			- Blog Grid
			- Blog Detail
	+ Footer
		+ Top Footer
			- Contact Minzel
			- Help & Support
			- Working Hours
			- Get In Touch
		+ Bottom Footer
		    - Copyright
	+ Responsive breakpoints
		    - $laptop_l: '(max-width: 1440px)';
            - $laptop_m: '(max-width: 1366px)';
            - $laptop_m_two: '(max-width: 1280px)';
            - $laptop_m_three: '(max-width: 1199px)';
            - $laptop_m_four: '(max-width: 1024px)';
            - $tab_device: 'only screen and (min-width: 768px) and (max-width: 991px)';
            - $large_mobile: 'only screen and (min-width: 480px) and (max-width: 767px)';
            - $small_mobile: 'only screen and (min-width: 320px) and (max-width: 479px)';
-------------------------------------------------------------------*/

// variable scss
@import "partials/variables";
// mixin scss
@import "partials/mixins";
// keyframse scss
@import "partials/keyframse";
// common scss
@import "partials/common";
// contact scss
@import "partials/contact";
// general scss
@import "partials/general";
// button scss
@import "partials/button";
// header scss
@import "partials/header";
// breadcrumb scss
@import "partials/breadcrumb";
// icon scss
@import "partials/icon";
// tabs scss
@import "partials/tabs";
// card scss
@import "partials/card";
// hero scss
@import "partials/hero";
// testimonial scss
@import "partials/testimonial";
// comment scss
@import "partials/comment";
// table scss
@import "partials/table";
// product scss
@import "partials/product";
// team-member scss
@import "partials/team-member";
// collapse scss
@import "partials/collapse";
// map scss
@import "partials/map";
// portfolio scss
@import "partials/portfolio";
// blog scss
@import "partials/blog";
// file-upload scss
@import "partials/file-upload";







