.single-team-img-container {
  position: relative;
  z-index: 1;
  img {
    width: 100%;
    @include border-radius(4px);
  }
  &:before {
    position: absolute;
    content: '';
    border: 10px solid rgba(35, 61, 99, 0.1);
    height: 100%;
    width: 100%;
    left: -40px;
    bottom: -40px;
    z-index: -1;
    @include border-radius(4px);
  }
  &::after {
    position: absolute;
    content: '';
    height: 50%;
    width: 50%;
    left: -68px;
    top: 50%;
    @include transform(translateY(-50%));
    background-image: url(../images/dots.png);
    background-size: cover;
    background-position: center;
    z-index: -1;
    opacity: 0.2;
  }
}