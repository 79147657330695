* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
ul{
  padding: 0;
  margin: 0;
  list-style-type: none;
}
a:hover,
a:focus{
  text-decoration: none;
}
input:focus,
button:focus {
  outline: none;
}
h1, h2, h3, h4, h5, h6 {
  margin: 0;
  color: $theme-color;
}
p {
  margin: 0;
}
body {
  font-size: $font-16;
  line-height: 25px;
  font-family: $theme-font;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: $font-weight-regular;
  color: $theme-color-4;
}
/*======= container =======*/
.container {
  max-width: 1240px;
}
@media (min-width:576px) {
  .container {
    max-width: 540px;
  }
}
@media (min-width:768px) {
  .container {
    max-width: 720px;
  }
}
@media (min-width:992px) {
  .container {
    max-width: 960px;
  }
}
@media (min-width:1200px) {
  .container {
    max-width: 1240px;
  }
}

.td-line-through {
  text-decoration: line-through;
}
.h-500 {
  height: 500px;
}
.theme-font-2 {
  font-family: $theme-font-2;
}
/*========= anim-bounce ==========*/
.anim-bounce {
  animation: heartBeat 1000ms infinite linear;
}
/*========= z-index ==========*/
.z-index-0 {
  z-index: 0!important;
}
.z-index-1 {
  z-index: 1!important;
}
.z-index-n1 {
  z-index: -1!important;
}
/*========= radius ==========*/
.rounded-6 {
  @include border-radius(6px!important);
}
/*========= position ==========*/
.top-0 {
  top: 0!important;
}
.right-0 {
  right: 0!important;
}
.bottom-0 {
  bottom: 0!important;
}
.left-0 {
  left: 0!important;
}
/*========= font-weight ==========*/
.fw-medium {
  font-weight: $font-weight-medium!important;
}
.fw-semi-bold {
  font-weight: $font-weight-semi-bold!important;
}
/*========= bg ==========*/
.bg-gradient {
  background: #f4897f;
  background: -moz-linear-gradient(left,  #f4897f 0%, #f66b5d 100%);
  background: -webkit-linear-gradient(left,  #f4897f 0%,#f66b5d 100%);
  background: linear-gradient(to right,  #f4897f 0%,#f66b5d 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f4897f', endColorstr='#f66b5d',GradientType=1 );
}
.bg-gray {
  background-color: $gray!important;
}
.bg-blue {
  background-color: $blue-2!important;
}
.bg-cyan {
  background-color: $cyan!important;
}

/*========= border-color ==========*/
.border-gray {
  border-color: rgba(128, 137, 150, 0.1)!important;
}
.border-top-gray {
  border-top-color: rgba(128, 137, 150, 0.1)!important;
}
.border-right-gray {
  border-right-color: rgba(128, 137, 150, 0.1)!important;
}
.border-bottom-gray {
  border-bottom-color: rgba(128, 137, 150, 0.1)!important;
}
.border-left-gray {
  border-left-color: rgba(128, 137, 150, 0.1)!important;
}
.border-blue {
  border-color: $blue-2!important;
}
.border-cyan {
  border-color: $cyan!important;
}
/*========= text color ==========*/
.text-black {
  color: $theme-color!important;
}
.text-gray {
  color: $gray-2 !important;
}
.text-gray-2 {
  color: $gray-4 !important;
}
.text-color-1 {
  color: $theme-color-2!important;
}
/*========= font sizes ==========*/
.fs-70 {
  font-size: 70px!important;
}
.fs-60{
  font-size: 60px!important;
}
.fs-50 {
  font-size: 50px!important;
}
.fs-40 {
  font-size: 40px!important;
}
.fs-20 {
  font-size: 20px!important;
}
.fs-18 {
  font-size: 18px!important;
}
.fs-17 {
  font-size: 17px!important;
}
.fs-16 {
  font-size: 16px!important;
}
.fs-15 {
  font-size: 15px!important;
}
.fs-14 {
  font-size: 14px!important;
}
.fs-13 {
  font-size: 13px!important;
}
.fs-12 {
  font-size: 12px!important;
}
.fs-11 {
  font-size: 11px!important;
}
/*========= spacing ==========*/
.pt-100 {
  padding-top: 100px!important;
}
.pt-90 {
  padding-top: 90px!important;
}
.pt-80 {
  padding-top: 80px!important;
}
.pt-70 {
  padding-top: 70px!important;
}
.pt-60 {
  padding-top: 60px!important;
}
.pt-50 {
  padding-top: 50px!important;
}
/* padding bottom */
.pb-100 {
  padding-bottom: 100px!important;
}
.pb-90 {
  padding-bottom: 90px!important;
}
.pb-80 {
  padding-bottom: 80px!important;
}
.pb-70 {
  padding-bottom: 70px!important;
}
.pb-60 {
  padding-bottom: 60px!important;
}
.pb-50 {
  padding-bottom: 50px!important;
}
/*========= line-height ==========*/
.lh-20 {
  line-height: 20px !important;
}
.lh-18 {
  line-height: 18px !important;
}