.bread-bg {
  background-image: url(../images/page-header-bg.jpg);
}
.breadcrumb {
  background-color: transparent;
  @include border-radius(0);
  margin: 0;
  padding: 0;
}
.breadcrumb-item {
  a {
    color: $theme-color;
    @include transition(0.1s);
    &:hover {
      color: $theme-color-2;
      text-decoration: underline;
    }
  }
  +.breadcrumb-item {
    &:before {
      content: "\f105";
      font-family: "Font Awesome 5 Pro";
      vertical-align: middle;
      color: $theme-color-4;
    }
  }
}