.nav-pills {
  border-bottom: 0;
  .nav-link {
    border-color: $theme-color-4;
    color: $theme-color;
    padding: 15px 30px;
    font-size: $font-18;
    font-weight: $font-weight-medium;
    margin-right: 8px;
    margin-bottom: 8px;
    @include transition(0.1s);
    &.active,
    &:hover {
      background-color: $theme-color-2;
      border-color: $theme-color-2;
      color: $white;
    }
  }
}
.nav--pills {
  .nav-link {
    padding: 10px 20px;
  }
}
.product-gallery-nav {
  margin-top: 20px;
  border: none;
  margin-left: -5px;
  margin-right: -5px;
  .nav-item {
    width: 33%;
  }
  .nav-link {
    display: block;
    padding: 5px;
    @include transition(0.2s);
    border: 0;
    img {
      width: 100%;
      border: 2px solid transparent;
    }
    &.active {
      img {
        border-color: $theme-color-2;
        @include border-radius(4px);
      }
    }
  }
}