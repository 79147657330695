$theme-color: #202124;
$theme-color-2: #F66B5D;
$theme-color-3: #f55242;
$theme-color-4: #5d6371;
$theme-font: 'Hind', sans-serif;
$theme-font-2: 'Oswald', sans-serif;
/*==== font sizes ====*/
$font-140: 140px;
$font-120: 120px;
$font-90: 90px;
$font-70: 70px;
$font-65: 65px;
$font-58: 58px;
$font-55: 55px;
$font-50: 50px;
$font-45: 45px;
$font-40: 40px;
$font-35: 35px;
$font-30: 30px;
$font-28: 28px;
$font-25: 25px;
$font-24: 24px;
$font-22: 22px;
$font-20: 20px;
$font-18: 18px;
$font-17: 17px;
$font-16: 16px;
$font-15: 15px;
$font-14: 14px;
$font-13: 13px;
$font-12: 12px;

/*==== colors ====*/
$white: #ffffff;
$black: #000000;
$gray: #fafafa;
$gray-2: #eeeeee;
$gray-3: #d4d4d4;
$gray-4: #c1c1c1;
$color-1: #f9b851;
$brown: #cc9966;
$blue: #3399cc;
$purple: #9966cc;
$green: #669933;
$blue-2: #4267B2;
$cyan: #00acee;


/*==== font weight ====*/
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;

/*==== breakpoints ====*/
$laptop_l: '(max-width: 1440px)';
$laptop_m: '(max-width: 1366px)';
$laptop_m_two: '(max-width: 1280px)';
$laptop_m_three: '(max-width: 1199px)';
$laptop_m_four: '(max-width: 1024px)';
$tab_device: 'only screen and (min-width: 768px) and (max-width: 991px)';
$large_mobile: 'only screen and (min-width: 480px) and (max-width: 767px)';
$small_mobile: 'only screen and (min-width: 320px) and (max-width: 479px)';
$device-575px: '(max-width: 575px)';