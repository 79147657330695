.portfolio-filter {
  li {
    display: inline-block;
    padding: 6px 20px;
    cursor: pointer;
    color: $theme-color-4;
    @include border-radius(6px);
    @include transition(0.3s);
    font-size: $font-18;
    font-weight: $font-weight-medium;
    text-transform: capitalize;
    &.active {
      background-color: $theme-color-2;
      color: $white;
    }
    &:hover:not(.active) {
      background-color: $gray-2;
      color: $theme-color;
    }
  }
}
/*==== portfolio-item ====*/
.portfolio-item {
  position: relative;
  overflow: hidden;
  @include border-radius(4px);
  margin-bottom: 30px;
  .overlay {
    opacity: 0;
    visibility: hidden;
    z-index: 1;
    @include transition(0.3s);
    @include border-radius(4px);
  }
  .portfolio-img {
    width: 100%;
    @include border-radius(4px);
    @include transition(0.4s);
  }
  &:hover {
    .portfolio-content {
      opacity: 1;
      visibility: visible;
    }
    .overlay {
      opacity: 0.6;
      visibility: visible;
    }
    .portfolio-title {
      @include transform(translateY(0));
    }
    .portfolio-img {
      @include transform(scale(1.2));
    }
  }
}
/*==== portfolio-content ====*/
.portfolio-content {
  opacity: 0;
  visibility: hidden;
  @include transition(0.3s);
  .portfolio-title {
    @include transform(translateY(20px));
    @include transition(0.3s);
  }
  a {
    color: $white;
    @include transition(0.3s);
    &:hover {
      color: $theme-color-2;
    }
  }
}