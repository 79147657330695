.label-text {
  color: $theme-color;
  font-weight: $font-weight-medium;
  font-size: $font-14;
}
.form--control {
  height: 50px;
  border-color: rgba(128, 137, 150, 0.2);
  font-size: $font-15;
  color: $theme-color;
  &:focus {
    @include box-shadow(none);
    border-color: $theme-color-2;
  }
}
.form--control-sm {
  height: 40px;
}
.custom-control-label {
  vertical-align: middle;
}
.custom-control-label,
.custom-select {
  cursor: pointer;
}
.custom--select {
  height: 50px;
}
/*======== bootstrap-tagsinput =======*/
.bootstrap-tagsinput {
  @include box-shadow(none);
  color: $theme-color;
  border-color: rgba(128, 137, 150, 0.3);
  width: 100%;
  font-size: $font-14;
  padding: 12px 16px;
  &.focus {
    border-color: rgba(128, 137, 150, 0.6);
  }
  .tag {
    font-size: $font-12;
  }
}
/*======== checkout-discount =======*/
.discount-form-control {
  border-style: dashed;
}
.checkout-discount {
  position: relative;
  max-width: 350px;
  label {
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    padding-left: 1.1rem;
    padding-right: 1.1rem;
    transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    opacity: 1;
    transition: all .3s;
  }
}
/*======== payment-method =======*/
.payment-method {
  .payment-method-label {
    margin-bottom: 0;
    color: $theme-color;
    position: relative;
    padding-top: 14px;
    padding-bottom: 14px;
    padding-left: 30px;
    display: block;
    cursor: pointer;
    input {
      opacity: 0;
      visibility: hidden;
      position: absolute;
    }
    &:before {
      border: 2px solid $gray-2;
      @include border-radius(100%);
      content: "";
      position: absolute;
      top: 18px;
      left: 0;
      width: 18px;
      height: 18px;
      @include transition(0.3s);
    }
    &:after {
      background-color: $theme-color-2;
      @include border-radius(100%);
      content: "";
      position: absolute;
      left: 5px;
      top: 23px;
      width: 8px;
      height: 8px;
      @include transition(0.3s);
      @include transform(scale(0));
    }
  }
  li {
    background-color: $white;
    @include border-radius(4px);
    @include box-shadow(0 1px 8px rgba(82, 85, 90, 0.1));
    margin-bottom: 15px;
    padding-right: 20px;
    padding-left: 20px;
    &.active {
      .payment-method-label {
        &:before {
          border-color: $theme-color-2;
        }
        &:after {
          background-color: $theme-color-2;
          @include transform(scale(1));
        }
      }
      .expanded-payment-method {
        display: block;
      }
    }
  }
}
.expanded-payment-method {
  display: none;
  font-weight: $font-weight-regular;
  padding-bottom: 14px;
  p {
    font-size: $font-15;
  }
}
.alert-message {
  display: none;
}
/*=========== toggle-password =============*/
.toggle-password {
  display: inline-flex;
  align-items: center;
  &:focus {
    @include box-shadow(none);
  }
  &:hover {
    color: $theme-color-2;
  }
  .eye-off {
    display: none;
  }
  &.active {
    .eye-off {
      display: block;
    }
    .eye-on {
      display: none;
    }
  }
}