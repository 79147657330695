.random-img {
  width: 100px;
  height: 100px;
  @include border-radius(50%);
  position: absolute;
  top: 0;
  left: 0;
  @media #{$tab_device} {
    display: none;
  }
  @media #{$large_mobile} {
    display: none;
  }
  @media #{$small_mobile} {
    display: none;
  }
  &:nth-child(1) {
    top: -5px;
    left: -10px;
  }
  &:nth-child(2) {
    top: 26%;
    left: 11%;
    width: 70px;
    height: 70px;
  }
  &:nth-child(3) {
    top: 55%;
    left: 6%;
    width: 60px;
    height: 60px;
  }
  &:nth-child(4) {
    top: auto;
    bottom: 8%;
    left: 15%;
    width: 50px;
    height: 50px;
  }
  &:nth-child(5) {
    top: 10px;
    left: auto;
    right: 5px;
  }
  &:nth-child(6) {
    top: 30%;
    left: auto;
    right: 10%;
    width: 70px;
    height: 70px;
  }
  &:nth-child(7) {
    top: auto;
    left: auto;
    bottom: 30%;
    right: 6%;
    width: 60px;
    height: 60px;
  }
  &:nth-child(8) {
    top: auto;
    left: auto;
    bottom: 3%;
    right: 14%;
    width: 45px;
    height: 45px;
  }
}