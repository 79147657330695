.icon-element {
  width: 90px;
  height: 90px;
  line-height: 95px;
  text-align: center;
  @include border-radius(100%);
  background-color: $white;
  @include box-shadow(0 1px 3px rgba(82, 85, 90, 0.15));
  font-size: $font-35;
  color: $theme-color;
  display: inline-block;
}
.icon-element-sm {
  width: 50px;
  height: 50px;
  line-height: 55px;
  font-size: $font-24;
}
.icon-element-xs {
  width: 40px;
  height: 40px;
  line-height: 45px;
  font-size: $font-16;
}
.icon-element-xs-2 {
  width: 35px;
  height: 35px;
  line-height: 40px;
  font-size: $font-14;
}
a.icon-element {
  @include transition(0.2s);
  &:hover {
    color: $theme-color-2;
  }
}