.btn {
  font-weight: $font-weight-medium;
  padding: 12px 20px;
  &:focus,
  &.focus {
    @include box-shadow(none);
  }
}
.btn-sm {
  padding: 6px 12px;
}
/*=== btn-primary ===*/
.btn-primary {
  background-color: $theme-color-2;
  border-color: $theme-color-2;
  &:focus,
  &.focus {
    @include box-shadow(none);
  }
  &:focus,
  &.focus,
  &:hover {
    background-color: $theme-color-3;
    border-color: $theme-color-3;
  }
}
/*=== btn-outline ===*/
.btn-outline-primary {
  border-color: $theme-color-2;
  color: $theme-color-2;
  &:hover {
    background-color: $theme-color-2;
    border-color: $theme-color-2;
  }
}
/*=== btn-link ===*/
.btn-link {
  color: $theme-color-4;
  font-weight: $font-weight-medium;
  &:hover {
    color: $theme-color-2;
  }
}