@mixin transition($transitionProperty) {
  -webkit-transition: all $transitionProperty;
  -moz-transition: all $transitionProperty;
  -ms-transition: all $transitionProperty;
  -o-transition: all $transitionProperty;
  transition: all $transitionProperty;
}
@mixin transform($transformProperty) {
  -webkit-transform: $transformProperty;
  -moz-transform: $transformProperty;
  -ms-transform: $transformProperty;
  -o-transform: $transformProperty;
  transform: $transformProperty;
}
@mixin border-radius($radius-property) {
  -webkit-border-radius: $radius-property;
  -moz-border-radius: $radius-property;
  border-radius: $radius-property;
}
@mixin box-shadow($boxShadowProperty) {
  -webkit-box-shadow: $boxShadowProperty;
  -moz-box-shadow: $boxShadowProperty;
  box-shadow: $boxShadowProperty;
}