.table {
  color: $theme-color;
  thead {
    th {
      border-bottom: 0;
      border-top: 0;
      font-weight: $font-weight-medium;
    }
  }
  th,
  td {
    padding-left: 0;
    vertical-align: middle;
  }
  td {
    padding-top: 25px;
    padding-bottom: 25px;
    border-top-color: rgba(128, 137, 150, 0.1);
  }
}

.table-summary {
  line-height: 1.43;
  thead th,
  tbody td {
    &:last-child {
      text-align: right;
      padding-right: 0;
    }
  }
  .product-title {
    max-width: 100px;
    a {
      color: $theme-color-4;
    }
  }
  td {
    padding-top: 12px;
    padding-bottom: 12px;
    color: $theme-color-4;
  }
  .summary-subtotal {
    font-weight: $font-weight-medium;
    td {
      color: $theme-color;
    }
  }
  .summary-total {
    font-weight: $font-weight-medium;
    td {
      color: $theme-color-2;
    }
  }
}