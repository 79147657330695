/*===== product-action-list =====*/
.product-action-list {
  position: absolute;
  top: 15px;
  left: -60px;
  background-color: $white;
  @include transition(0.3s);
  font-size: $font-18;
  z-index: 1;
  li {
    a {
      color: $theme-color;
      padding: 9px 13px;
      display: block;
      @include transition(0.3s);
      border-bottom: 1px solid $gray-2;
      &:hover {
        background-color: $theme-color-2;
        color: $white;
      }
    }
    &:last-child {
      a {
        border-bottom: 0;
      }
    }
  }
}

.product-title {
  font-size: $font-18;
  a {
    color: $theme-color;
    @include transition(0.2s);
    &:hover {
      color: $theme-color-2;
    }
  }
}