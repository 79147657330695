/*==== pattern-bg =====*/
.pattern-bg {
  position: relative;
  z-index: 1;
  &:after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(../images/dots2.png);
    background-size: cover;
    background-position: center;
    opacity: 0.2;
    z-index: -1;
  }
}
/*========= bg-img ==========*/
.bg-img {
  background-image: url(../images/img6.jpg);
  background-size: cover;
  background-position: center;
}
/*========= section-padding ==========*/
.section-padding {
  padding-top: 100px;
  padding-bottom: 100px;
}
.section--padding {
  padding-top: 100px;
  padding-bottom: 70px;
}
/*========= preloader ==========*/
.loader-container {
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: $white;
  top: 0;
  left: 0;
  z-index: 99999;
}
.loader-ripple {
  position: relative;
  width: 64px;
  height: 64px;
  top: 50%;
  left: 50%;
  @include transform(translate(-50%, -50%));
  div {
    position: absolute;
    border: 4px solid $theme-color-2;
    opacity: 1;
    @include border-radius(100%);
    animation: loader-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
    &:nth-child(2) {
      animation-delay: -0.5s;
    }
  }
}
/*========= hover ==========*/
.hover-y {
  &:hover {
    @include transform(translateY(-3px));
  }
}
.hover-s {
  &:hover {
    @include transform(scale(1.02));
  }
}
.hover-y,
.hover-s {
  @include transition(0.3s);
}
/*========= overlay ==========*/
.overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: $black;
  z-index: -1;
  opacity: 0.6;
}
/*========= sec-title ==========*/
.sec-title {
  font-size: $font-40;
  font-weight: $font-weight-bold;
  @media #{$small_mobile} {
    font-size: $font-30;
  }
  &.fs-60 {
    @media #{$small_mobile} {
      font-size: $font-30!important;
    }
  }
}
/*========= sec-desc ==========*/
.sec-desc {
  font-size: $font-18;
  line-height: 30px;
}
.sec-title,
.sec-desc {
  br {
    @media #{$laptop_m_four} {
      display: none;
    }
  }
}
/*========= list-item ==========*/
.list-item {
  li {
    margin-bottom: 7px;
    a {
      color: $theme-color-4;
      @include transition(0.2s);
      &:hover {
        color: $theme-color-2;
      }
    }
  }
}
/*========= list-item-white ==========*/
.list-item-white {
  li {
    a {
      color: $white;
    }
  }
}
/*========= list-item-bullet ==========*/
.list-item-bullet {
  li {
    &::before {
      content: "\2022";
      color: $theme-color-4;
      display: inline-block;
      width: 16px;
    }
  }
}
/*========= list-group-service ==========*/
.list-group-service {
  .list-group-item {
    @include border-radius(4px);
    margin-bottom: 10px;
    padding: 25px;
    +.list-group-item {
      border-top-width: 1px;
    }
    &.active {
      background-color: $theme-color-2;
      border-color: $theme-color-2;
      h5 {
        color: $white;
      }
    }
  }
}
/*========= list-group-item ==========*/
.list-group-item {
  a {
    color: $theme-color-4;
    @include transition(0.2s);
    &:hover {
      color: $theme-color-2;
    }
  }
}
/*========= list-group-flush ==========*/
.list-group-flush {
  .list-group-item {
    padding-left: 0;
    padding-right: 0;
    border-bottom-color: rgba(128, 137, 150, 0.1);
  }
}
/*========= filter-colors ==========*/
.filter-colors {
  display: flex;
  align-items: center;
  a {
    position: relative;
    display: inline-block;
    width: 28px;
    height: 28px;
    @include border-radius(100%);
    border: .2rem solid $white;
    margin-right: 1px;
    margin-left: 1px;
    @include transition(0.2s);
    &.selected,
    &:hover {
      @include box-shadow(0 0 0 0.1rem $gray-2);
    }
    &.brown {
      background-color: $brown;
    }
    &.blue {
      background-color: $blue;
    }
    &.purple {
      background-color: $purple;
    }
    &.black {
      background-color: $black;
    }
    &.green {
      background-color: $green;
    }
    &:after {
      color: $white;
      font-family: "Font Awesome 5 Pro";
      content: "\f00c";
      position: absolute;
      left: 50%;
      top: 50%;
      @include transform(translate(-50%, -50%));
      font-size: $font-14;
      opacity: 0;
      visibility: hidden;
    }
    &.selected {
      &:after {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}
.filter--colors {
  a {
    width: 22px;
    height: 22px;
  }
}
/*========= custom-control-input ==========*/
.custom-control-input {
  &:checked {
    ~ .custom-control-label {
      &:before {
        background-color: $theme-color-2;
        border-color: $theme-color-2;
      }
    }
  }
  &:focus {
    &:not(:checked) {
      ~ .custom-control-label {
        &:before {
          border-color: $theme-color-2;
        }
      }
    }
    ~ .custom-control-label {
      &:before {
        @include box-shadow(0 0 0 0.2rem rgba(246, 107, 93, .25));
      }
    }
  }
}
/*====== qtyBtn ======*/
.quantity-box {
  background-color: $white;
  @include box-shadow(0 0 5px rgba(0,0,0, 0.1));
  @include border-radius(4px);
  line-height: 1;
}
/*====== qtyBtn ======*/
.qtyBtn {
  font-size: $font-16;
  color: $theme-color;
  text-align: center;
  @include transition(0.3s);
  padding: 10px 15px;
  &:not(:last-child) {
    border-right: 1px solid rgba(128, 137, 150, 0.1);
  }
  &:not(:first-child) {
    border-left: 1px solid rgba(128, 137, 150, 0.1);
  }
  &:hover {
    color: $theme-color-2;
  }
}
/*====== qtyInput ======*/
.qtyInput {
  width: 40px;
  border: 0;
  text-align: center;
  color: $theme-color;
  font-weight: $font-weight-medium;
  pointer-events: none;
  font-size: $font-16;
  background-color: transparent;
}
/*===== custom-table =====*/
.blockquote {
  border-left: 5px solid $gray-2;
  padding: 10px 20px;
}
/*===== custom-table =====*/
.custom-table {
  display: flex;
  border: 1px solid $gray-2;
  font-weight: $font-weight-medium;
  margin-bottom: -1px;
  @media #{$small_mobile} {
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .custom-table-name {
    width: 230px;
    flex-shrink: 0;
    border-right: 1px solid $gray-2;
    color: $theme-color;
    @media #{$large_mobile} {
      width: 100px;
    }
    @media #{$small_mobile} {
      width: auto;
      border-right: 0;
      border-bottom: 1px solid $gray-2;
    }
  }
  .custom-table-name,
  .custom-table-value {
    padding: 10px 15px;
  }
}
/*======= pagination-list ========*/
.pagination-list {
  .page-item {
    &.active {
      .page-link {
        background-color: $theme-color-2;
        &:hover {
          color: $white;
        }
      }
    }
    &:first-child {
      .page-link {
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
      }
    }
    &:last-child {
      .page-link {
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
      }
    }
  }
  .page-link {
    margin-left: 3px;
    border: 0;
    @include border-radius(6px);
    color: $theme-color-4;
    &:hover {
      color: $theme-color;
    }
  }
}
/*====== leave-rating ========*/
.leave-rating {
  display: table;
  border: 1px solid rgba(128, 137, 150, 0.2);
  padding: 20px;
  @include border-radius(4px);
  input[type="radio"] {
    display: none;
    &:hover,
    &:checked {
      ~ label {
        &:before {
          color: $color-1;
          font-weight: $font-weight-bold;
        }
      }
    }
  }
  label {
    float: right;
    letter-spacing: 4px;
    cursor: pointer;
    @include transition(0.3s);
    margin-bottom: 0;
    font-size: $font-22;
    &:before {
      color: $gray-3;
      font-weight: $font-weight-regular;
    }
  }
}
/*========= owl-theme-styled ==========*/
.owl-theme-styled {
  .owl-nav {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    @include transform(translateY(-50%));
    display: -ms-flex;
    display: flex;
    justify-content: space-between;
    padding-right: 20px;
    padding-left: 20px;
    [class*=owl-] {
      background: $white!important;
      width: 35px;
      height: 35px;
      line-height: 40px!important;
      text-align: center;
      @include border-radius(100%);
      @include box-shadow(0 1px 10px rgba(0,0,0, 0.1));
      color: $theme-color!important;
      font-size: $font-22!important;
      outline: none;
      @include transition(0.2s);
      &:hover {
        background: $theme-color-2!important;
        color: $white!important;
      }
    }
  }
  .owl-dots {
    .owl-dot {
      &:hover,
      &.active {
        span {
          background: $theme-color-2;
        }
      }
    }
  }
}
/*========= owl-theme--styled ==========*/
.owl-theme--styled {
  .owl-nav {
    padding-right: 0;
    padding-left: 0;
    z-index: -1;
    .owl-prev {
      margin-left: -40px;
    }
    .owl-next {
      margin-right: -40px;
    }
  }
}

/*==== fun-fact-item =====*/
.fun-fact-item {
  .icon-element {
    @include transition(0.2s);
  }
  &:hover {
    .icon-element {
      @include transform(translateY(-3px));
    }
  }
}
/*==== myChartLegend =====*/
#myChartLegend {
  li {
    cursor: pointer;
    color: $theme-color-4;
    font-size: $font-15;
    display: inline-block;
    font-weight: $font-weight-medium;
    span {
      display: inline-block;
      @include border-radius(100%);
      height: 10px;
      margin-right: 8px;
      width: 10px;
    }
    &:first-child {
      margin-right: 20px;
      span {
        background-color: #4E73DF !important;
      }
    }
    &:last-child {
      span {
        background-color: $theme-color-2 !important;
      }
    }
    &.legend-active {
      text-decoration: line-through;
    }
  }
}
/*======== owl-item =========*/
.card-slider,
.testimonial-slider{
  .owl-item {
    padding: 15px;
  }
  .owl-dots {
    margin-top: 0!important;
  }
}
/* star rating */
.star-rating {
  line-height: 18px;
  .star {
    display: inline-block;
    padding: 0;
    margin-right: 4px;
    position: relative;
    &.half::after,
    &::before {
      font-family: "FontAwesome", sans-serif;
      content: "\f005";
      display: block;
      color: $color-1;
    }
    &.half {
      &::before {
        color: $gray-3;
      }
    }
    &.half {
      &::after {
        color: $color-1;
        position: absolute;
        top: 0;
        width: 50%;
        display: block;
        height: 100%;
        overflow: hidden;
      }
    }
    &.empty {
      &::before {
        color: $gray-3;
      }
    }
  }
}
/* rating-counter */
.rating-counter {
  color: $theme-color-4;
  padding-left: 3px;
  display: inline-block;
  font-size: $font-14;
}
/*====== summernote ======*/
.note-editable {
  ul {
    list-style-type: disc;
  }
  ul,
  ol {
    padding-left: 30px;
  }
}
.note-editor.note-airframe,
.note-editor.note-frame {
  border-color: rgba(128, 137, 150, 0.3);
  @include box-shadow(none);
  margin-bottom: 0;
  &:hover {
    @include box-shadow(none);
  }
}
/*======== pre =========*/
pre {
  line-height: 1.42857143;
  word-break: break-all;
  word-wrap: break-word;
  background-color: $gray-2;
  border: 1px solid rgba(128, 137, 150, 0.2);
  @include border-radius(4px);
  padding: 5px 10px;
}
/*======== back-to-top =========*/
#back-to-top {
  position: fixed;
  right: -150px;
  bottom: 30px;
  z-index: 9999;
  width: 35px;
  height: 35px;
  text-align: center;
  line-height: 35px;
  @include border-radius(100%);
  background-color: $white;
  @include box-shadow(0 1px 10px rgba(82, 85, 90, 0.1));
  color: $theme-color;
  @include transition(0.4s);
  cursor: pointer;
  opacity: 0;
  visibility: hidden;
  &:hover {
    background-color: $theme-color-2;
    color: $white;
    @include transform(scale(1.1));
  }
  &.active {
    right: 20px;
    opacity: 1;
    visibility: visible;
  }
}





