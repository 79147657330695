.hero-bg {
  background-image: url("../images/hero-img1.jpg");
}
.hero-bg-2 {
  background-image: url("../images/hero-img2.jpg");
}
.hero-bg-3 {
  background-image: url("../images/hero-img3.jpg");
}
.hero-bg-4 {
  background-image: url("../images/shop-slider1.jpg");
}
.hero-bg-5 {
  background-image: url("../images/shop-slider2.jpg");
}
.hero-bg-6 {
  background-image: url("../images/shop-slider3.jpg");
}
.hero-bg-7 {
  background-image: url("../images/hero-img4.jpg");
}

.video-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1;
}
.hero-area{
  background-size: cover;
  background-position: center;
  position: relative;
}
.hero-item {
  padding-top: 200px;
  padding-bottom: 200px;
  @media #{$small_mobile} {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}
.hero--item {
  padding-top: 150px;
  padding-bottom: 150px;
  @media #{$small_mobile} {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

.hero-slider {
  .owl-item {
    &.active {
      .sec-title,
      .sec-desc,
      .hero-btns {
        -webkit-animation-duration: 1s;
        animation-duration: 1s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        animation-delay: 0.3s;
      }
      .sec-title,
      .sec-desc {
        animation-name: fadeInDown;
      }
      .hero-btns {
        animation-name: fadeInUp;
      }
    }
  }
}
