/*==== card ====*/
.card {
  @include border-radius(6px);
  border: 0;
  @include box-shadow(0 1px 10px rgba(82, 85, 90, 0.08));
  margin-bottom: 30px;
  &:hover {
    .product-action-list {
      left: 0;
    }
    .add-to-cart-btn {
      opacity: 1;
      visibility: visible;
      bottom: 15px;
    }
  }
}
/*==== card-head ====*/
.card-head {
  position: relative;
  img {
    width: 100%;
  }
  .card-img-top {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }
  .card-img-right {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    @media #{$large_mobile} {
      border-top-right-radius: 0;
      border-bottom-left-radius: 6px;
    }
    @media #{$small_mobile} {
      border-top-right-radius: 0;
      border-bottom-left-radius: 6px;
    }
  }
  .add-to-cart-btn {
    position: absolute;
    bottom: 0;
    left: 50%;
    @include transform(translateX(-50%));
    @include transition(0.3s);
    opacity: 0;
    visibility: hidden;
  }
  .badge {
    position: absolute;
    top: 15px;
    right: 15px;
  }
}
/*==== card-body ====*/
.card-body {
  padding: 30px;
}
/*==== card-title ====*/
.card-title {
  font-size: $font-22;
  font-weight: $font-weight-semi-bold;
  a {
    color: $theme-color;
    @include transition(0.2s);
    &:hover {
      color: $theme-color-2;
    }
  }
}
/*==== card-text ====*/
.card-text {
  a {
    color: $theme-color-4;
    @include transition(0.2s);
    &:hover {
      color: $theme-color-2;
    }
  }
}
/*==== card-flex ====*/
.card-flex {
  -ms-flex-direction: row;
  flex-direction: row;
  @media #{$large_mobile} {
    -ms-flex-direction: column;
    flex-direction: column;
  }
  @media #{$small_mobile} {
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .card-head {
    flex: 1;
    img {
      height: 100%;
      object-fit: cover;
    }
  }
  .card-img-top {
    border-top-right-radius: 0;
    border-bottom-left-radius: 6px;
    @media #{$large_mobile} {
      border-top-right-radius: 6px;
      border-bottom-left-radius: 0;
    }
    @media #{$small_mobile} {
      border-top-right-radius: 6px;
      border-bottom-left-radius: 0;
    }
  }
  .card-body {
    flex: 1;
  }
}
/*==== media-title =====*/
.media-img {
  @include border-radius(4px);
  width: 70px;
  img {
    width: 100%;
    @include border-radius(4px);
  }
}
.media-img-sm {
  width: 55px;
}
/*==== media-title =====*/
.media-title {
  font-size: $font-18;
}
.media-title,
.media-text {
  a {
    color: $theme-color;
    @include transition(0.2s);
    &:hover {
      color: $theme-color-2;
    }
  }
}

.number-icon {
  font-size: $font-140;
  font-weight: $font-weight-bold;
  color: $white;
  opacity: 0.1;
  position: absolute;
  right: 10px;
  bottom: 13px;
}