/*==== zoom-control ====*/
.zoom-control {
  @include border-radius(6px);
  @include box-shadow(0 0 3px rgba(0,0,0, 0.3));
  background-color: $white;
  div {
    &:first-child {
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
    }
    &:last-child {
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
      border-bottom: 0;
    }
  }
}
.zoom-in,
.zoom-out {
  width: 30px;
  height: 30px;
  line-height: 30px;
  background-color: $white;
  border-bottom: 1px solid $gray-2;
  text-align: center;
  color: $theme-color;
  cursor: pointer;
  font-size: $font-16;
  &:hover {
    background-color: $gray-2;
  }
}

/*==== leaflet-container ======*/
.leaflet-container {
  .leaflet-bar,
  .leaflet-control-layers {
    border: 0;
  }
  .leaflet-bar {
    @include box-shadow(0 1px 5px rgba(0,0,0,.2));
    a {
      border-bottom-color: rgba(128, 137, 150, 0.1);
      color: $theme-color;
      &:first-child {
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
      }
      &:last-child {
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
      }
      &:hover {
        border-bottom-color: rgba(128, 137, 150, 0.1);
      }
    }
  }
}